<template>
  <div class="products-by-sub">
    <div class="row">
      <div class="col-lg-6 py-3">
        <div class="bread-crumbs">
          <div class="bread-crumb-item clickable" @click="displayCategories">
            Categories
          </div>
          <div class="bread-crumb-item separator">
            <i class="fa fa-chevron-right"></i>
          </div>
          <div class="bread-crumb-item clickable" @click="displaySubCategories">
            {{ this.categoryName }}
          </div>
          <div class="bread-crumb-item separator">
            <i class="fa fa-chevron-right"></i>
          </div>
          <div class="bread-crumb-item">
            <SubCatsDropdown
              :subCatName="subCatName"
              :subCatId="subCatId"
              :catId="catId"
              @updateProducts="updateProducts"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div style="display: grid; place-items: center" class="w-100 h-100">
          <span><b>Total Records:</b> {{ rows }}</span>
        </div>
      </div>
      <div
        class="col-lg-3 d-flex align-items-center justify-content-end py-3"
        v-if="rows > 10"
      >
        <div class="row w-100">
          <span class="col-4 d-flex align-items-center">Products Per Page</span>
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            class="col-8"
          ></b-form-select>
        </div>
      </div>
    </div>

    <b-table
      hover
      :busy="fetchingRecords"
      :items="tableProducts"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      class="table-light"
      thead-class="active-products-table-head shadow-sm"
      tbody-class="active-products-table-body"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mx-4"></b-spinner>
          <strong>Fetching...</strong>
        </div>
      </template>
      <template v-slot:cell(images)="data">
        <StackedImages :images="data.value" type="product" />
      </template>
      <template v-slot:cell(product_rating)="data">
        <div class="info-rating-block">
          <div class="info">
            <span class="ratings-block">
              <i
                v-for="index in 5"
                class="fa fa-star"
                v-bind:class="getGoldClass(parseInt(data.value), index)"
                :key="index"
              ></i>
            </span>
            <span style="margin-left: 2.5px">{{ data.value }}.0</span>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { GET_SUB_PRODUCTS } from "@/core/services/store/categories.module";
import { mapState, mapGetters } from "vuex";
import StackedImages from "@/view/layout/dashboard_components/StackedImages";
import SubCatsDropdown from "@/view/pages/categories/SubCatsDropdown";
export default {
  components: {
    StackedImages,
    SubCatsDropdown,
  },
  props: {
    subCategoryId: String,
    subCategoryName: String,
    categoryName: String,
    categoryId: String,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      subCatId: this.subCategoryId,
      subCatName: this.subCategoryName,
      catId: this.categoryId,
      tableFields: [
        {
          key: "code",
          label: "Code",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "weight",
          label: "Weight",
          sortable: true,
        },
        {
          key: "price",
          label: "Price",
          sortable: true,
        },
        {
          key: "discount_price",
          label: "Discount Price",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "product_rating",
          label: "Product Rating",
          sortable: true,
        },
        {
          key: "product_rave",
          label: "Product Rave",
          sortable: true,
        },
        {
          key: "images",
          label: "images",
          sortable: true,
        },
      ],
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(GET_SUB_PRODUCTS, {
      subCategoryId: this.subCatId,
    });
  },
  methods: {
    ...mapGetters([
      "getSubProducts",
      "getSubProductsCount",
      "getSubProductsFetching",
    ]),
    getGoldClass: function (total, key) {
      return key <= total ? "gold" : "";
    },
    displayCategories: function () {
      this.$emit("displayCategories");
    },
    displaySubCategories: function () {
      this.$emit("displaySubCategories");
    },
    updateProducts: function ({ id, name }) {
      this.subCatId = id;
      this.subCatName = name;
      this.$store.dispatch(GET_SUB_PRODUCTS, {
        subCategoryId: this.subCatId,
      });
    },
  },
  computed: {
    ...mapState({
      products: function (state) {
        return state.categories.subProducts;
      },
      rows: function (state) {
        return state.categories.subProductsCount;
      },
      fetchingRecords: function (state) {
        return state.categories.subProductsFetching;
      },
      tableProducts: function (state) {
        let arr = [];
        state.categories.subProducts.map(function (item) {
          const tableItem = {
            code: item.sku,
            name: item.name,
            weight: "750 g",
            price: `$${item.price}`,
            discount_price: `$${item.discountedPrice}`,
            description: item.description,

            product_rating: item.avgRating,
            product_rave: item.raving ? "Yes" : "No",
            images: item.images,
          };
          arr.push(tableItem);
        });
        return arr;
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
.bread-crumbs {
  display: flex;
  .bread-crumb-item {
    font-size: 22px;
    margin: 0 5px;
    color: #3f4254;
    &.clickable {
      color: #5da3f9;
      cursor: pointer;
    }
    &.separator {
      i {
        font-size: 22px;
        color: #3f4254;
      }
    }
  }
}
::v-deep .b-table {
  .active-products-table-head {
    tr {
      th {
        color: #763fb8;
        width: 8.75%;
        font-size: 11px;
        vertical-align: top;
        width: 7.14%;
        &:nth-child(6) {
          width: 20%;
        }
        &:nth-child(7) {
          width: 15%;
        }
        &:nth-child(10) {
          width: 15%;
        }
      }
    }
  }
  .active-products-table-body {
    tr {
      td {
        vertical-align: middle;
        font-size: 12px;
        .action-cell {
          width: 100%;
          display: flex;
          .action-btn {
            width: 75px;
            padding: 5px 10px;
            display: flex;
            justify-content: center;
            margin-right: 5px;
            color: #fff;
            cursor: pointer;
            border-radius: 15px;
            &.edit {
              background: #4ff86f;
            }
            &.archive {
              background: #f8b040;
            }
          }
        }
        .info-rating-block {
          display: flex;
          flex-direction: column;
          .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            // font-weight: 600;
            .ratings-block {
              i {
                font-size: 10px;
              }
              i.gold {
                color: #ff9500;
              }
            }
          }
        }
      }
    }
  }
}
</style>
