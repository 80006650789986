<template>
  <div class="sub-categories">
    <b-row>
      <b-col cols="12" class="d-flex justify-content-end">
        <div @click="createNewSubCategory" class="create-new-btn shadow-sm">
          Create New SubCategory
        </div>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-lg-6 py-3">
        <div class="bread-crumbs">
          <div class="bread-crumb-item clickable" @click="displayCategories">
            SubCategories
          </div>
          <div class="bread-crumb-item separator">
            <i class="fa fa-chevron-right"></i>
          </div>
          <div class="bread-crumb-item">
            <CatsDropdown
              :catId="catId"
              :catName="catName"
              @updateCategories="updateCategories"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div
          class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
        >
          <span><b>Total Records:</b> {{ rows }}</span>
          <b-form-checkbox
            id="archived-cats"
            name="archvied-cats"
            value="archive"
            v-model="archiveDummy"
            unchecked-value="active"
            :disabled="fetchingRecords"
            switch
            @change="changeArchived"
          >
            Archived
          </b-form-checkbox>
        </div>
      </div>
      <div
        class="col-lg-3 d-flex align-items-center justify-content-end py-3"
        v-if="rows > 10"
      >
        <div class="row w-100">
          <span class="col-4 d-flex align-items-center"
            >Sub categories Per Page</span
          >
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            class="col-8"
            @change="perPageChange"
          ></b-form-select>
        </div>
      </div>
    </div>

    <b-table
      hover
      :busy="fetchingRecords"
      :items="tableSubCats"
      :fields="tableFields"
      :per-page="perPage"
      class="table-light"
      thead-class="categories-table-head shadow-sm"
      tbody-class="categories-table-body"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mx-4"></b-spinner>
          <strong>Fetching...</strong>
        </div>
      </template>
      <template v-slot:cell(image)="data">
        <StackedImages :images="[data.value]" type="product" />
      </template>
      <template v-slot:cell(action)="data">
        <div class="action-cell">
          <div class="single-row">
            <span class="action-btn edit" @click="editSubCat(data.value.index)"
              >Edit</span
            >
            <template v-if="archiveDummy === 'active'">
              <span
                class="action-btn archive"
                @click="archiveSubcategory(data.value)"
                >Archive</span
              >
            </template>
            <template v-else>
              <span
                class="action-btn sub-cats"
                @click="unarchive(data.value.index)"
              >
                Unarchive
              </span>
            </template>
          </div>
          <span
            class="action-btn sub-cats"
            @click="displayProducts(data.value.subCatId, data.value.subCatName)"
          >
            View Products
          </span>
        </div>
      </template>
    </b-table>
    <div class="d-flex justify-content-center">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        :current-page="currentPage"
        @change="changePage"
      ></b-pagination>
    </div>
    <template v-if="newSub">
      <new-sub-category
        :categoryName="categoryName"
        :categoryId="categoryId"
        :businessLineName="businessLineName"
        @closeNewSub="closeNewSub"
        @createdNewSub="newSubCreated"
        ref="newSubRef"
      />
    </template>
    <template v-if="subCatToEdit !== null">
      <edit-sub-category
        :categoryName="categoryName"
        :categoryId="categoryId"
        :subCatToEdit="subCatToEdit"
        @closeEditSub="closeEditSub"
        @editedSub="subEdited"
        ref="editSubRef"
      />
    </template>
    <template v-if="subCatToUnarchive !== null">
      <unarchive-sub-cat
        :subCategory="subCatToUnarchive"
        @close="closeUnarchive"
        @done="doneUnarchive"
        ref="unarchiveSubRef"
      />
    </template>
  </div>
</template>

<script>
import { GET_SUB_CATEGORIES } from "@/core/services/store/categories.module";
import CatsDropdown from "@/view/pages/categories/CatsDropdown";
import ApiService from "@/core/services/api.service";
import { mapState, mapGetters } from "vuex";
import StackedImages from "@/view/layout/dashboard_components/StackedImages";
import NewSubCat from "@/view/pages/categories/NewSubCat";
import EditSubCat from "@/view/pages/categories/EditSubCat";
import UnArchiveSubcategory from "@/view/pages/categories/UnArchiveSubcategory";

export default {
  components: {
    StackedImages,
    CatsDropdown,
    "new-sub-category": NewSubCat,
    "edit-sub-category": EditSubCat,
    "unarchive-sub-cat": UnArchiveSubcategory,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      catId: this.categoryId,
      catName: this.categoryName,
      businessLineName: this.businessLine,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      tableFields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "image",
          label: "Image",
          sortable: true,
        },
        {
          key: "products_attached",
          label: "Products Attached",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },

        {
          key: "action",
          label: "Action",
          sortable: true,
        },
      ],
      archiveDummy: "active",
      newSub: false,
      subCatToEdit: null,
      subCatToUnarchive: null,
    };
  },
  props: {
    categoryId: String,
    categoryName: String,
    businessLine: String,
  },
  mounted() {
    this.$store.dispatch(GET_SUB_CATEGORIES, {
      categoryId: this.catId,
      archived: 0,
    });
  },
  methods: {
    ...mapGetters[("getAllSubCats", "getCategoriesFetching", "getAllSubCats")],

    hideSubCategories: function () {
      this.$emit("hideSubCategories");
    },
    displayCategories: function () {
      this.$emit("displayCategories");
    },
    displayProducts: function (id, name) {
      this.$emit("displayProducts", { id, name });
    },
    updateCategories: function ({ id, name, businessLineName }) {
      this.catId = id;
      this.catName = name;
      this.businessLineName = businessLineName;

      this.$store.dispatch(GET_SUB_CATEGORIES, {
        categoryId: this.catId,
        archived: this.archiveDummy === "active" ? 0 : 1,
        limit: [(this.currentPage - 1) * this.perPage, this.perPage],
      });
      this.$emit("displaySubCategories", { id, name });
    },
    async updateComponent(a = this.currentPage, b = this.perPage) {
      await this.$store.dispatch(GET_SUB_CATEGORIES, {
        categoryId: this.catId,
        archived: this.archiveDummy === "active" ? 0 : 1,
        limit: [(a - 1) * b, b],
      });
    },
    changeArchived(val) {
      this.$store.dispatch(GET_SUB_CATEGORIES, {
        categoryId: this.catId,
        archived: val === "active" ? 0 : 1,
        limit: [(this.currentPage - 1) * this.perPage, this.perPage],
      });
    },
    perPageChange: async function (val) {
      await this.updateComponent(this.currentPage, val);
      this.perPage = val;
    },
    changePage: async function (val) {
      await this.updateComponent(val, this.perPage);
      this.currentPage = val;
    },
    viewProductsBySub: function () {
      this.$emit("viewProductsBySub", 12);
    },
    createNewSubCategory: function () {
      this.newSub = true;
      setTimeout(() => {
        this.$refs.newSubRef.showModal();
      }, 100);
    },
    closeNewSub: function () {
      setTimeout(() => {
        this.newSub = false;
      }, 100);
    },
    newSubCreated: function () {
      this.updateComponent(this.currentPage, this.perPage);
      this.$refs.newSubRef.closeModal();
      this.closeNewSub();
    },
    closeEditSub: function () {
      setTimeout(() => {
        this.subCatToEdit = null;
      }, 100);
    },
    subEdited: function () {
      this.updateComponent(this.currentPage, this.perPage);
      this.$refs.editSubRef.closeModal();
      this.closeEditSub();
    },
    editSubCat(index) {
      const temp = this.tableSubCats[index];
      this.subCatToEdit = temp;
    },
    archiveSubcategory: function (sub) {
      ApiService.post("/subCategories/archive", {
        subCategory: sub.subCatId,
      })
        .then(({ data: res }) => {
          if (res.success) {
            this.archivingError = `${sub.subCatName} was archived.`;

            setTimeout(() => {
              this.archivingError = "";
            }, 5500);

            setTimeout(() => {
              this.updateComponent();
            }, 100);
          }
        })
        .catch(({ data }) => {
          this.archivingError = `Archive Error: ${data.message} on ${data.url}`;
          setTimeout(() => {
            this.archivingError = "";
          }, 3500);
        });
    },
    unarchive: function (index) {
      this.subCatToUnarchive = this.tableSubCats[index];
      setTimeout(() => {
        this.$refs.unarchiveSubRef.showModal();
      }, 100);
    },
    closeUnarchive: function () {
      setTimeout(() => {
        this.subCatToUnarchive = null;
      }, 100);
    },
    doneUnarchive: function (msg) {
      this.updateComponent();
      this.$refs.unarchiveSubRef.closeModal();
      setTimeout(() => {
        this.archivingError = msg;
      }, 200);
      setTimeout(() => {
        this.archivingError = "";
      }, 3500);
    },
  },

  watch: {
    subCatToEdit: function (x) {
      if (x !== null) {
        setTimeout(() => {
          this.$refs.editSubRef.showModal();
        }, 100);
      }
    },
  },
  computed: {
    ...mapState({
      fetchingRecords: function (state) {
        return state.categories.subCategoriesFetching;
      },
      rows: function (state) {
        return state.categories.subCategoriesCount;
      },

      tableSubCats: function (state) {
        let arr = [];
        state.categories.subCategories.map(function (item, index) {
          const tableItem = {
            id: item._id,
            name: item.name,
            description: item.description,
            image: item.image,
            products_attached: item.products.length,
            status: !item.archived,
            category: item.category,
            stores: item.stores,
            action: {
              index,
              subCatId: item._id,
              subCatName: item.name,
            },
          };
          arr.push(tableItem);
        });
        return arr;
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
.create-new-btn {
  background: #fff;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 10px;
  font-weight: 700;
}
.bread-crumbs {
  display: flex;
  .bread-crumb-item {
    font-size: 22px;
    margin: 0 5px;
    color: #3f4254;
    &.clickable {
      color: #5da3f9;
      cursor: pointer;
    }
    &.separator {
      i {
        font-size: 22px;
        color: #3f4254;
      }
    }
  }
}
::v-deep .b-table {
  .categories-table-head {
    tr {
      th {
        color: #763fb8;
        width: 8.75%;
        font-size: 11px;
        vertical-align: top;
        width: 11.75%;
        &:nth-child(2) {
          width: 33%;
        }
        &:nth-child(6) {
          width: 20%;
        }
      }
    }
  }
  .categories-table-body {
    tr {
      td {
        width: 11.75%;
        &:nth-child(2) {
          width: 33%;
        }
        &:nth-child(6) {
          width: 20%;
        }
        vertical-align: middle;
        font-size: 12px;
        .action-cell {
          width: 100%;
          display: flex;
          flex-direction: column;
          .single-row {
            display: grid;
            grid-template-columns: auto auto;
          }
          .action-btn {
            padding: 5px 10px;
            margin: 2.5px;
            display: flex;
            justify-content: center;
            margin-right: 5px;
            color: #fff;
            cursor: pointer;
            border-radius: 15px;
            &.edit {
              background: #4ff86f;
            }
            &.archive {
              background: #f8b040;
            }
            &.sub-cats {
              background: #50a6fa;
            }
          }
        }
        .info-rating-block {
          display: flex;
          flex-direction: column;
          .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            // font-weight: 600;
            .ratings-block {
              i {
                font-size: 10px;
              }
              i.gold {
                color: #ff9500;
              }
            }
          }
        }
      }
    }
  }
}
</style>
