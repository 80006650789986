<template>
  <div>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-end">
        <div @click="createNewCategory" class="create-new-btn shadow-sm">
          Create New Category
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-center lead text-danger">
        <transition name="fade-in-up">
          <span v-if="archivingError !== ''">{{ archivingError }} </span>
        </transition></b-col
      >
    </b-row>
    <div class="row">
      <div class="col-lg-3 py-3">
        <h3>All Categories</h3>
      </div>
      <div class="col-lg-3 py-3">
        <b-form-checkbox
          id="archived-cats"
          name="archvied-cats"
          value="archive"
          v-model="archiveDummy"
          @change="changeArchive"
          unchecked-value="active"
          :disabled="fetchingRecords"
          switch
        >
          Archived
        </b-form-checkbox>
      </div>
      <div class="col-lg-3">
        <div style="display: grid; place-items: center" class="w-100 h-100">
          <span><b>Total Records:</b> {{ rows }}</span>
        </div>
      </div>
      <div
        class="col-lg-3 d-flex align-items-center justify-content-end py-3"
        v-if="rows > 10"
      >
        <div class="row w-100">
          <span class="col-4 d-flex align-items-center"
            >Categories Per Page</span
          >
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            class="col-8"
            @change="perPageChange"
          ></b-form-select>
        </div>
      </div>
    </div>
    <template v-if="networkError === null">
      <b-table
        hover
        :busy="fetchingRecords"
        :items="tableCats"
        :fields="tableFields"
        class="table-light"
        thead-class="categories-table-head shadow-sm"
        tbody-class="categories-table-body"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mx-4"></b-spinner>
            <strong>Fetching...</strong>
          </div>
        </template>
        <template v-slot:cell(image)="data">
          <StackedImages :images="[data.value]" type="product" />
        </template>

        <template v-slot:cell(action)="data">
          <div class="action-cell">
            <div class="single-row">
              <span
                class="action-btn edit"
                @click="editCategory(data.value.index)"
                >Edit</span
              >
              <template v-if="archiveDummy === 'active'">
                <span
                  class="action-btn archive"
                  @click="archiveCategory(data.value)"
                  >Archive</span
                >
              </template>
              <template v-else>
                <span
                  class="action-btn sub-cats"
                  @click="unArchiveCategory(data.value.index)"
                >
                  Unarchive
                </span>
              </template>
            </div>
            <span
              class="action-btn sub-cats"
              @click="
                viewSubcat(
                  data.value.catId,
                  data.value.catName,
                  data.value.businessLine.name
                )
              "
            >
              View Subcategories
            </span>
          </div>
        </template>
      </b-table>
    </template>
    <template v-else>
      <div class="d-flex flex-column align-items-center w-100">
        <div class="text-danger text-center display-4 p-4">API Error</div>
        <div class="text-danger text-center lead pb-4">
          Error while fetchting
          {{ archiveDummy === "archive" ? "archived" : "active" }} categories.
          Please refresh to try again.
        </div>
        <div class="text-danger text-center lead pb-4">
          {{ networkError }}
        </div>
      </div>
    </template>
    <template v-if="rows > 0">
      <div class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          :current-page="currentPage"
          @change="changePage"
        ></b-pagination>
      </div>
    </template>
    <template v-if="catToEdit !== null">
      <EditCat
        ref="editCatRef"
        @editedCat="editedCat"
        :catToEdit="catToEdit"
        @editCatClosed="editCatClosed"
      />
    </template>
    <template v-if="createCat">
      <new-cat
        ref="newCatRef"
        @createdCat="createdCat"
        @newCatClosed="newCatClosed"
      />
    </template>
    <template v-if="catToUnarchive !== null">
      <unarchive-cat
        ref="unArchiveCatRef"
        :category="catToUnarchive"
        @done="unArchiveCatDone"
        @close="unArchiveCatClosed"
      />
    </template>
  </div>
</template>
<script>
import StackedImages from "@/view/layout/dashboard_components/StackedImages";
import EditCat from "@/view/pages/categories/EditCat";
import NewCategory from "@/view/pages/categories/NewCategory";
import UnArchiveCategory from "@/view/pages/categories/UnArchiveCategory";
import ApiService from "@/core/services/api.service";
import {
  GET_ALL_CATEGORIES,
  GET_ARCHIVED_CATEGORIES,
} from "@/core/services/store/categories.module";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    StackedImages,
    EditCat,
    "new-cat": NewCategory,
    "unarchive-cat": UnArchiveCategory,
  },
  data() {
    return {
      archivingError: "",
      perPage: 10,
      currentPage: 1,
      showSubCategory: false,
      catToEdit: null,
      catToUnarchive: null,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
      tableFields: [
        {
          key: "businessLine",
          label: "Business Line",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "image",
          label: "Image",
          sortable: true,
        },
        {
          key: "products_attached",
          label: "Products Attached",
          sortable: true,
        },
        {
          key: "sub_category",
          label: "Subcategories",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
          sortable: true,
        },
      ],
      callingCats: GET_ALL_CATEGORIES,
      archiveDummy: "active",
      createCat: false,
    };
  },
  methods: {
    ...mapGetters([
      "getAllCategories",
      "getAllCategoriesCount",
      "getCategoriesFetching",
      "getArchivedCategories",
      "getArchivedCategoriesCount",
    ]),
    viewSubcat: function (id, name, businessLine) {
      this.$emit("displaySubCategories", { id, name, businessLine });
    },
    getGoldClass: function (total, key) {
      return key <= total ? "gold" : "";
    },
    editedCat: function () {
      this.$store.dispatch(this.callingCats, {
        limit: [(this.currentPage - 1) * this.perPage, this.perPage],
      });
      this.$refs.editCatRef.closeModal();
      this.catToEdit = null;
    },
    perPageChange(val) {
      this.$store.dispatch(this.callingCats, {
        limit: [(this.currentPage - 1) * val, val],
      });
    },
    changePage(val) {
      this.currentPage = val;
      this.$store.dispatch(this.callingCats, {
        limit: [(val - 1) * this.perPage, this.perPage],
      });
    },
    changeArchive(val = "active") {
      if (val === "active") this.callingCats = GET_ALL_CATEGORIES;
      else this.callingCats = GET_ARCHIVED_CATEGORIES;
      this.$store.dispatch(this.callingCats);
    },
    editCategory(index) {
      this.catToEdit = this.tableCats[index];
      setTimeout(() => {
        this.$refs.editCatRef.showModal();
      }, 100);
    },
    archiveCategory({ catId, catName }) {
      const dataToSend = {
        category: catId,
      };

      ApiService.post("/categories/archiveCategory", dataToSend)
        .then(({ data: res }) => {
          if (res.success) {
            this.archivingError = `${catName} was archived.`;

            setTimeout(() => {
              this.archivingError = "";
            }, 5500);

            setTimeout(() => {
              this.$store.dispatch(this.callingCats, {
                limit: [(this.currentPage - 1) * this.perPage, this.perPage],
              });
            }, 100);
          }
        })
        .catch(({ data }) => {
          this.archivingError = `Archive Error: ${data.message} on ${data.url}`;
          setTimeout(() => {
            this.archivingError = "";
          }, 3500);
        });
    },
    createNewCategory() {
      this.createCat = true;
    },
    newCatClosed() {
      this.createCat = false;
    },
    editCatClosed() {
      this.catToEdit = null;
    },
    createdCat() {
      this.$store.dispatch(this.callingCats, {
        limit: [(this.currentPage - 1) * this.perPage, this.perPage],
      });
      this.$refs.newCatRef.closeModal();
      setTimeout(() => {
        this.createCat = false;
      }, 100);
    },
    unArchiveCategory(index) {
      this.catToUnarchive = this.tableCats[index];
      setTimeout(() => {
        this.$refs.unArchiveCatRef.showModal();
      }, 100);
    },
    unArchiveCatClosed() {
      setTimeout(() => {
        this.catToUnarchive = null;
      }, 100);
    },
    unArchiveCatDone(msg) {
      this.$store.dispatch(this.callingCats, {
        limit: [(this.currentPage - 1) * this.perPage, this.perPage],
      });
      this.$refs.unArchiveCatRef.closeModal();

      setTimeout(() => {
        this.catToUnarchive = null;
      }, 100);
      setTimeout(() => {
        this.archivingError = msg;
      }, 200);
      setTimeout(() => {
        this.archivingError = "";
      }, 3500);
    },
  },
  mounted() {
    this.callingCats =
      this.archiveDummy === "active"
        ? GET_ALL_CATEGORIES
        : GET_ARCHIVED_CATEGORIES;

    this.$store.dispatch(this.callingCats);
  },
  computed: {
    ...mapState({
      fetchingRecords: function (state) {
        return this.callingCats === GET_ALL_CATEGORIES
          ? state.categories.categoriesFetching
          : state.categories.archviedCategoriesFetching;
      },
      networkError: function (state) {
        return this.callingCats === GET_ALL_CATEGORIES
          ? state.categories.activeCategoriesError
          : state.categories.archivedCategoriesError;
      },
      rows: function (state) {
        return this.callingCats === GET_ALL_CATEGORIES
          ? state.categories.allCategoriesCount
          : state.categories.archivedCategoriesCount;
      },
      tableCats: function (state) {
        let arr = [];
        let c =
          this.callingCats === GET_ALL_CATEGORIES
            ? state.categories.allCategories
            : state.categories.archivedCategories;
        c.map(function (item, index) {
          const businessLineName = item.businessLine.name;
          const tableItem = {
            name: item.name,
            businessLine: businessLineName,
            description: item.description,
            image: item.image,
            sub_category: item.subCategories.length,
            action: {
              index,
              catId: item._id,
              catName: item.name,
              businessLine: item.businessLine,
            },
          };
          arr.push(tableItem);
          console.log("tableItem: ", tableItem);
        });
        return arr;
      },
    }),
  },
  watch: {
    createCat: function (create) {
      if (create) {
        setTimeout(() => {
          this.$refs.newCatRef.showModal();
        }, 100);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .b-table {
  .categories-table-head {
    tr {
      th {
        color: #763fb8;
        width: 9%;
        font-size: 11px;
        vertical-align: top;

        &:nth-child(3) {
          width: 30%;
        }
        &:nth-child(7) {
          width: 25%;
        }
      }
    }
  }
  .categories-table-body {
    tr {
      td {
        width: 9%;
        &:nth-child(3) {
          width: 30%;
        }
        &:nth-child(7) {
          width: 25%;
        }
        vertical-align: middle;
        font-size: 12px;
        .action-cell {
          width: 100%;
          display: flex;
          flex-direction: column;
          .single-row {
            display: grid;
            grid-template-columns: auto auto;
          }
          .action-btn {
            padding: 5px 10px;
            margin: 2.5px;
            display: flex;
            justify-content: center;
            margin-right: 5px;
            color: #fff;
            cursor: pointer;
            border-radius: 15px;
            &.edit {
              background: #4ff86f;
            }
            &.archive {
              background: #f8b040;
            }
            &.sub-cats {
              background: #50a6fa;
            }
          }
        }
        .info-rating-block {
          display: flex;
          flex-direction: column;
          .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            // font-weight: 600;
            .ratings-block {
              i {
                font-size: 10px;
              }
              i.gold {
                color: #ff9500;
              }
            }
          }
        }
      }
    }
  }
}
</style>
